import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function DashboardMenu({ color = DefaultTheme.palette.grey[300] }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x={11} y={14} width={12} height={8} rx={1} stroke={color} strokeWidth={2} />
      <rect x={11} y={27} width={26} height={7} rx={1} stroke={color} strokeWidth={2} />
      <path d="M28 14h9M28 18h9M28 22h9" stroke={color} strokeWidth={2} strokeLinecap="round" />
    </svg>
  );
}

export default DashboardMenu;
