import React, { PropsWithChildren } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {
  LocalizationProvider as MuiLocalizationProvider,
  DatePicker as MuiDatePicker,
  DatePickerProps,
} from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { InputLabel, Theme } from '@mui/material';
import { Icon } from '../Icon';
import { EIconTypes } from '../../constants';
import { makeStyles } from '@mui/styles';
import { LocalizationProviderProps } from '@mui/lab/LocalizationProvider/LocalizationProvider';
import { Input } from '../Input';

type TLocalizationProviderProps = PropsWithChildren<{ providerProps?: LocalizationProviderProps }>;

export function LocalizationProvider({ providerProps, children }: TLocalizationProviderProps) {
  return (
    <MuiLocalizationProvider
      dateAdapter={DateAdapter}
      libInstance={moment}
      utils={MomentUtils}
      locale={moment.locale()}
      {...providerProps}>
      {children}
    </MuiLocalizationProvider>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    padding: theme.spacing(1, 2),
  },
}));

export type TDatePickerProps<TDate = unknown> = Omit<DatePickerProps<TDate>, 'renderInput'> & {
  providerProps?: LocalizationProviderProps;
  formLabel?: string;
  placeholder?: string;
};

export function DatePicker<TDate = unknown>({
  providerProps,
  formLabel,
  placeholder,
  ...datePickerProps
}: TDatePickerProps<TDate>): React.ReactElement {
  const classes = useStyles();
  return (
    <LocalizationProvider {...providerProps}>
      <>
        {formLabel ? <InputLabel>{formLabel}</InputLabel> : null}
        <MuiDatePicker
          renderInput={({ inputProps, ...otherParams }) => (
            <Input
              inputProps={{
                ...inputProps,
                classes: {
                  input: classes.input,
                },
                placeholder: placeholder || inputProps.placeholder,
              }}
              {...otherParams}
            />
          )}
          components={{
            OpenPickerIcon: props => <Icon type={EIconTypes.ICON_CALENDAR} {...props} />,
          }}
          {...datePickerProps}
        />
      </>
    </LocalizationProvider>
  );
}
