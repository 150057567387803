type TFontWeight = 'FONT_WEIGHT_BOLD' | 'FONT_WEIGHT_NORMAL';

const FONT_WEIGHT_BOLD: TFontWeight = 'FONT_WEIGHT_BOLD';
const FONT_WEIGHT_NORMAL: TFontWeight = 'FONT_WEIGHT_NORMAL';

const FontWeightMap: Record<TFontWeight, number> = {
  [FONT_WEIGHT_BOLD]: 600,
  [FONT_WEIGHT_NORMAL]: 400,
};
const getFontStyles = (fontWeight: TFontWeight, fontSize: number, lineHeight: number) => ({
  fontWeight: FontWeightMap[fontWeight],
  fontSize: `${fontSize}rem`,
  lineHeight,
});

export const fonts = {
  BOLD_1_15: getFontStyles(FONT_WEIGHT_BOLD, 1, 1.5),
  BOLD_875_15: getFontStyles(FONT_WEIGHT_BOLD, 0.875, 1.5),
  BOLD_675_24: getFontStyles(FONT_WEIGHT_BOLD, 0.675, 2.4),

  NORMAL_1_175: getFontStyles(FONT_WEIGHT_NORMAL, 1, 1.75),
  NORMAL_1_15: getFontStyles(FONT_WEIGHT_NORMAL, 1, 1.5),
  NORMAL_875_17: getFontStyles(FONT_WEIGHT_NORMAL, 0.875, 1.7),
  NORMAL_875_125: getFontStyles(FONT_WEIGHT_NORMAL, 0.875, 1.25),
  NORMAL_75_2: getFontStyles(FONT_WEIGHT_NORMAL, 0.75, 2),
};
