import React from 'react';
import { Select as MuiSelect, MenuItem as MuiMenuItem, InputLabel, Theme, SelectProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { fonts } from '../../themes/base/fonts';
import { Loading } from '../Loading';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    alignItems: 'center',
  },
  selectRoot: {
    ...fonts.NORMAL_1_15,
  },
  select: {
    height: '1.5rem',
    padding: theme.spacing(2),
  },
  underline: {
    borderBottom: 'none',
  },
  placeHolder: {
    color: theme.palette.grey[300],
    ...fonts.NORMAL_1_15,
  },
  disabled: {
    color: 'rgb(208 206 206)',
  },
  wrapper: {
    position: 'relative',
  },
  overlay: {
    backgroundColor: theme.palette.secondary.light,
  },
  icon: {},
}));
type TValue = string | number;
export type TOption = { value: TValue; label: string };
export type TSelectProps<T> = SelectProps<T> & {
  formLabel?: string;
  placeholder?: string;
  options: TOption[];
  isLoading?: boolean;
};
const filterOption = (option: TOption, selected: TValue | TValue[]) => {
  if (typeof selected === 'string' || typeof selected === 'number') {
    return option.value === selected;
  } else if (selected?.length) {
    return selected.indexOf(option.value) >= 0;
  }
};
export function Select<T extends TValue | TValue[]>({
  classes: classesProp = {},
  formLabel,
  variant = 'outlined',
  options,
  placeholder = '',
  isLoading,
  disabled,
  ...otherProps
}: TSelectProps<T>): React.ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {formLabel ? <InputLabel>{formLabel}</InputLabel> : null}
      <div className={classes.wrapper}>
        <MuiSelect
          displayEmpty
          variant={variant}
          classes={{
            root: clsx(classes.selectRoot, classesProp.root),
            select: clsx(classes.select, classesProp.select),
            icon: clsx(classes.icon, classesProp.icon),
          }}
          renderValue={selected => {
            if ((!selected && selected !== 0) || (selected as string | string[]).length === 0) {
              return (
                <small className={clsx(classes.placeHolder, { [classes.disabled]: disabled })}>{placeholder}</small>
              );
            }
            return (
              options
                .filter(option => filterOption(option, selected))
                ?.map(v => v.label)
                ?.join(', ') || placeholder
            );
          }}
          disabled={disabled}
          {...otherProps}>
          {options.map(option => (
            <MuiMenuItem
              classes={{
                root: classes.selectRoot,
              }}
              value={option.value}
              key={option.value}>
              {option.label}
            </MuiMenuItem>
          ))}
        </MuiSelect>
        {isLoading ? (
          <Loading
            align={'left'}
            classes={{
              overlay: classes.overlay,
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
