import * as React from 'react';

import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function Minus({ color = DefaultTheme.palette.grey[200] }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx={10} cy={10} r={9} stroke={color} strokeWidth={2} />
      <path d="M6 10h8" stroke={color} strokeWidth={2} strokeLinecap="square" />
    </svg>
  );
}

export default Minus;
