import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function Setting({ color = DefaultTheme.palette.grey[300] }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.16 24.561c.04-.337.07-.674.07-1.032s-.03-.695-.07-1.032l2.11-1.737a.55.55 0 00.12-.674l-2-3.643a.495.495 0 00-.61-.232l-2.49 1.053a7.228 7.228 0 00-1.69-1.031l-.38-2.79a.496.496 0 00-.49-.443h-4c-.25 0-.46.19-.49.442l-.38 2.79c-.61.264-1.17.622-1.69 1.032l-2.49-1.052a.491.491 0 00-.61.232l-2 3.642a.538.538 0 00.12.674l2.11 1.737c-.04.337-.07.685-.07 1.032 0 .348.03.695.07 1.032L14.19 26.3a.55.55 0 00-.12.674l2 3.643a.495.495 0 00.61.231l2.49-1.053c.52.422 1.08.77 1.69 1.032l.38 2.79c.03.253.24.443.49.443h4c.25 0 .46-.19.49-.442l.38-2.79a7.596 7.596 0 001.69-1.033l2.49 1.053a.491.491 0 00.61-.232l2-3.643a.55.55 0 00-.12-.673l-2.11-1.738zm-1.98-1.8c.04.326.05.547.05.768 0 .221-.02.453-.05.77l-.14 1.189.89.737 1.08.884-.7 1.274-1.27-.537-1.04-.442-.9.716c-.43.337-.84.59-1.25.769l-1.06.453-.16 1.19-.2 1.421h-1.4l-.19-1.421-.16-1.19-1.06-.453c-.43-.19-.83-.432-1.23-.748l-.91-.737-1.06.453-1.27.537-.7-1.274 1.08-.885.89-.737-.14-1.19a8.912 8.912 0 01-.05-.779c0-.21.02-.452.05-.768l.14-1.19-.89-.737-1.08-.885.7-1.274 1.27.537 1.04.443.9-.716c.43-.337.84-.59 1.25-.77l1.06-.452.16-1.19.2-1.421h1.39l.19 1.421.16 1.19 1.06.453c.43.19.83.432 1.23.747l.91.738 1.06-.453 1.27-.537.7 1.274-1.07.895-.89.737.14 1.19zm-5.45-3.443c-2.21 0-4 1.884-4 4.211 0 2.327 1.79 4.212 4 4.212s4-1.885 4-4.212-1.79-4.211-4-4.211zm0 6.317c-1.1 0-2-.947-2-2.106 0-1.158.9-2.105 2-2.105s2 .947 2 2.105c0 1.159-.9 2.106-2 2.106z"
        fill={color}
      />
    </svg>
  );
}

export default Setting;
