import React from 'react';
import {
  InputLabel,
  Theme,
  Autocomplete as MuiAutocomplete,
  ComponentsPropsList,
  autocompleteClasses,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { fonts } from '../../themes/base/fonts';
import { Input } from '../Input';
import { TObject } from '../../common/types';
import clsx from 'clsx';
import { Loading } from '../Loading';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    alignItems: 'center',
  },
  wrapper: {
    position: 'relative',
  },
  selectRoot: {
    ...fonts.NORMAL_75_2,
  },
  inputRoot: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  select: {
    padding: theme.spacing(1, 2),
  },
  underline: {
    borderBottom: 'none',
  },
  input: {
    ...fonts.NORMAL_1_15,
  },
  option: {
    ...fonts.NORMAL_1_15,
  },
  overlay: {
    backgroundColor: theme.palette.secondary.light,
  },
  endAdornment: {},
}));
export type TOption = TObject & { value: string | number; label: string };
export type TAutoCompleteProps = Omit<ComponentsPropsList['MuiAutocomplete'], 'renderInput'> & {
  formLabel?: string;
  options: TOption[];
  inputProps?: ComponentsPropsList['MuiTextField'];
  isLoading?: boolean;
};

export function AutoComplete({
  id,
  classes: classesProp = {},
  formLabel,
  options,
  inputProps = {},
  isLoading,
  sx: sxProp,
  ...otherProps
}: TAutoCompleteProps): React.ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {formLabel ? <InputLabel>{formLabel}</InputLabel> : null}
      <div className={classes.wrapper}>
        <MuiAutocomplete
          id={`autocomplete-${id}`}
          options={options}
          classes={{
            ...classesProp,
            root: classes.selectRoot,
            inputRoot: classes.inputRoot,
            option: classes.option,
            endAdornment: classes.endAdornment,
          }}
          sx={{
            ['& .MuiOutlinedInput-root']: {
              padding: 1,
              [`& .${autocompleteClasses.input}`]: {
                padding: 1,
              },
            },
            ...sxProp,
          }}
          renderInput={params => (
            <Input classes={{ root: clsx(classes.input, inputProps.classes) }} {...inputProps} {...params} />
          )}
          {...otherProps}
        />
        {isLoading ? (
          <Loading
            align={'left'}
            classes={{
              overlay: classes.overlay,
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
