import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function RadioChecked({ color = DefaultTheme.palette.primary.main }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={52} height={52} fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x={17} y={17} width={18} height={18} rx={9} stroke={color} strokeWidth={2} />
      <circle cx={26} cy={26} r={4} fill={color} />
    </svg>
  );
}

export default RadioChecked;
