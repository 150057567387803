import React from 'react';
import { Chip as MuiChip, ComponentsPropsList, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { fonts } from '../../themes/base/fonts';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(0.75, 0),
  },
  label: {
    padding: theme.spacing(0, 1.5),
    ...fonts.NORMAL_875_125,
  },
  bgSuccess: {
    backgroundColor: theme.palette.success.light,
  },
  success: {
    color: theme.palette.success.main,
  },
  bgDanger: {
    backgroundColor: theme.palette.error.light,
  },
  danger: {
    color: theme.palette.error.main,
  },
}));
enum EType {
  default = 'default',
  success = 'success',
  error = 'error',
}
export type TChipProps = {
  classes?: ComponentsPropsList['MuiChip']['classes'];
  variant?: ComponentsPropsList['MuiChip']['variant'];
  label: ComponentsPropsList['MuiChip']['label'];
  color?: ComponentsPropsList['MuiChip']['color'];
  type?: keyof typeof EType;
};
export function Chip({ classes: classesProp = {}, type = EType.default, ...props }: TChipProps): React.ReactElement {
  const classes = useStyles();
  return (
    <MuiChip
      classes={{
        root: clsx(
          classes.root,
          {
            [classes.bgSuccess]: type === EType.success,
            [classes.bgDanger]: type === EType.error,
          },
          classesProp.root,
        ),
        label: clsx(
          classes.label,
          {
            [classes.success]: type === EType.success,
            [classes.danger]: type === EType.error,
          },
          classesProp.label,
        ),
      }}
      {...props}
    />
  );
}
