import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function ChecklistMenu({ color = DefaultTheme.palette.grey[300] }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx={15} cy={24} r={2} stroke={color} strokeWidth={2} />
      <circle cx={15} cy={33} r={2} stroke={color} strokeWidth={2} />
      <rect x={22} y={13} width={13} height={4} rx={2} stroke={color} strokeWidth={2} />
      <rect x={22} y={22} width={13} height={4} rx={2} stroke={color} strokeWidth={2} />
      <rect x={22} y={31} width={13} height={4} rx={2} stroke={color} strokeWidth={2} />
      <path
        d="M13.998 17L12 15M13.999 16.999l4.003-4"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ChecklistMenu;
