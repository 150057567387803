import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function Location({ color = DefaultTheme.palette.error.main }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={18} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.138 8.69c0 1.401-.465 2.997-1.241 4.66-.77 1.65-1.81 3.288-2.868 4.75a47.66 47.66 0 01-3.34 4.092 47.659 47.659 0 01-3.34-4.093c-1.057-1.461-2.096-3.098-2.866-4.749-.776-1.663-1.242-3.259-1.242-4.66a7.448 7.448 0 0114.897 0z"
        stroke={color}
        strokeWidth={2.483}
      />
      <circle cx={8.689} cy={8.689} r={2.483} fill={color} stroke={color} strokeWidth={0.828} />
    </svg>
  );
}

export default Location;
