import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function DecreaseArrow({ color = DefaultTheme.palette.error.main }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 9h-2v6.59L5.41 4 4 5.41 15.59 17H9v2h10V9z" fill={color} />
    </svg>
  );
}

export default DecreaseArrow;
