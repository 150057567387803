import React from 'react';
import {
  ComponentsPropsList,
  FormControlLabel,
  Switch as MuiSwitch,
  Typography as MuiTypography,
  Theme,
} from '@mui/material';
import { fonts } from '../../themes/base/fonts';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiTypography-root': {
      ...fonts.NORMAL_875_17,
      color: theme.palette.text.primary,
    },
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  formLabel: {
    ...fonts.BOLD_1_15,
    marginRight: theme.spacing(2.5),
  },
}));

export type TSwitchProps = ComponentsPropsList['MuiSwitch'] & {
  formLabel?: string;
  label: string;
};

export function Switch({ formLabel, label, color, ...otherProps }: TSwitchProps): React.ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.switchContainer}>
      {formLabel ? (
        <MuiTypography classes={{ root: classes.formLabel }} color="textPrimary" variant="subtitle1">
          {formLabel}
        </MuiTypography>
      ) : null}
      {label ? (
        <FormControlLabel
          control={<MuiSwitch color={color} {...otherProps} />}
          classes={{ root: classes.root }}
          label={label}
        />
      ) : (
        <MuiSwitch color={color} {...otherProps} />
      )}
    </div>
  );
}
