import React from 'react';
import { ComponentsPropsList, IconButton as MuiIconButton, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { EIconTypes } from '../../constants';
import { EIconColor, Icon } from '../Icon';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
  },
  minusBtn: {
    marginRight: theme.spacing(2),
  },
  plusBtn: {
    marginLeft: theme.spacing(2),
  },
  value: {
    width: theme.spacing(3),
    textAlign: 'center',
  },
}));

export type TCountButtonProps = {
  value: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  onMinusClick: ComponentsPropsList['MuiIconButton']['onClick'];
  onPlusClick: ComponentsPropsList['MuiIconButton']['onClick'];
  minusProps?: ComponentsPropsList['MuiIconButton'];
  plusProps?: ComponentsPropsList['MuiIconButton'];
};

export function CountButton({
  value,
  min,
  max,
  disabled,
  minusProps,
  plusProps,
  onMinusClick,
  onPlusClick,
}: TCountButtonProps): React.ReactElement {
  const classes = useStyles();

  const minusDisable = disabled || Boolean((min || min === 0) && value <= min);
  const plusDisable = disabled || Boolean(max && value >= max);

  return (
    <div className={classes.root}>
      <MuiIconButton className={classes.minusBtn} disabled={minusDisable} onClick={onMinusClick} {...minusProps}>
        <Icon type={EIconTypes.ICON_MINUS} color={minusDisable ? EIconColor.grey200 : EIconColor.textSecondary} />
      </MuiIconButton>
      <Typography variant="subtitle1" className={classes.value}>
        {value}
      </Typography>
      <MuiIconButton className={classes.plusBtn} disabled={plusDisable} onClick={onPlusClick} {...plusProps}>
        <Icon type={EIconTypes.ICON_PLUS} color={plusDisable ? EIconColor.grey200 : EIconColor.textSecondary} />
      </MuiIconButton>
    </div>
  );
}
