import * as React from 'react';

const Reward3 = () => (
  <svg width={20} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.016 1.025c0-.29.235-.525.524-.525h12.92c.29 0 .524.235.524.525v5.36c0 2.19-.932 4.276-2.563 5.736l-2.592 2.32a.525.525 0 0 1-.35.134H8.521a.525.525 0 0 1-.35-.134l-2.592-2.32a7.697 7.697 0 0 1-2.563-5.735V1.025Z"
      fill="#FF9500"
      stroke="#262222"
      strokeLinejoin="round"
    />
    <path
      d="M9.34 6.344h.527a.9.9 0 0 0 .417-.086c.11-.059.19-.141.242-.246a.808.808 0 0 0 .082-.372.733.733 0 0 0-.075-.339.51.51 0 0 0-.222-.232.76.76 0 0 0-.38-.086.782.782 0 0 0-.334.072.613.613 0 0 0-.246.202.529.529 0 0 0-.093.314h-.987c0-.273.072-.511.218-.714.148-.203.347-.361.595-.475.248-.114.522-.171.82-.171.338 0 .633.055.886.164.253.107.45.265.59.475.142.21.213.47.213.78 0 .157-.037.31-.11.457a1.285 1.285 0 0 1-.314.397c-.135.116-.299.21-.492.28a1.953 1.953 0 0 1-.653.103H9.34v-.523Zm0 .748v-.509h.684c.271 0 .51.03.714.092.205.062.377.15.516.267.14.114.244.25.315.407.07.155.106.327.106.516 0 .232-.045.44-.134.622-.088.18-.214.332-.376.458-.159.125-.346.22-.56.287a2.446 2.446 0 0 1-1.32.01c-.2-.06-.382-.147-.546-.263a1.354 1.354 0 0 1-.39-.444 1.343 1.343 0 0 1-.143-.64h.987c0 .128.032.242.096.342a.66.66 0 0 0 .263.236c.114.057.24.086.38.086a.879.879 0 0 0 .403-.086c.114-.06.2-.141.26-.246a.735.735 0 0 0 .092-.373.84.84 0 0 0-.1-.437.588.588 0 0 0-.283-.246 1.111 1.111 0 0 0-.437-.079H9.34Z"
      fill="#262222"
    />
    <path
      d="M5.526 19.6a3.087 3.087 0 0 1 3.07-2.769h2.806a3.087 3.087 0 0 1 3.07 2.769H5.527Z"
      fill="#FF9500"
      stroke="#262222"
    />
    <path fill="#FF9500" stroke="#262222" d="M8.629 14.081h2.742v2.769H8.629z" />
    <path
      d="M1.025 2.384h1.71l.47 7.388-1.43-.834A2.574 2.574 0 0 1 .5 6.715V2.91c0-.29.235-.525.525-.525ZM18.975 2.384h-1.71l-.47 7.388 1.43-.834A2.574 2.574 0 0 0 19.5 6.715V2.91a.525.525 0 0 0-.525-.525Z"
      stroke="#262222"
      strokeLinejoin="round"
    />
  </svg>
);

export default Reward3;
