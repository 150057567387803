import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function CheckboxChecked({ color = DefaultTheme.palette.primary.main }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={52} height={52} fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x={17} y={17} width={18} height={18} rx={1} fill="inherit" stroke={color} strokeWidth={2} />
      <path d="M31 22l-7 7-3-2.999" stroke={color} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default CheckboxChecked;
