import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function DownArrow({ color = DefaultTheme.palette.grey[300], className }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={10} height={6} fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M9 1L5 5 1 1" stroke={color} fill="none" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default DownArrow;
