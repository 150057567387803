import * as React from 'react';
import { TSvgTypeProps } from './type';
import { DefaultTheme } from '../themes/base';

function Humidity({ color = DefaultTheme.palette.text.primary }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.245 9.238 12 5 7.755 9.238A6.015 6.015 0 0 0 6 13.468c0 1.5.585 3.082 1.755 4.252a5.993 5.993 0 0 0 8.49 0A6.066 6.066 0 0 0 18 13.468c0-1.5-.585-3.06-1.755-4.23Zm-8.745 4.5c.008-1.5.465-2.453 1.32-3.3L12 7.19l3.18 3.285c.855.84 1.313 1.763 1.32 3.263h-9Z"
        fill={color}
      />
      <path d="m7.5 11.113 4.125-4.5 4.875 4.5.75 1.5-.75 1.875-8.625.375-1.125-1.125.75-2.625Z" fill={color} />
    </svg>
  );
}

export default Humidity;
