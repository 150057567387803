import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

const StatusNotStarted = ({ color = DefaultTheme.palette.grey[200] }: TSvgTypeProps) => (
  <svg width={20} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x={1} y={5} width={18} height={18} rx={9} stroke={color} strokeWidth={2} />
    <path d="M6.84 14.182h6.788" stroke={color} strokeWidth={2} strokeLinecap="round" />
    <path stroke={color} d="M9.5 2.5h1v2h-1z" />
    <rect x={11.5} y={0.5} width={1} height={3} rx={0.5} transform="rotate(90 11.5 .5)" stroke={color} />
  </svg>
);

export default StatusNotStarted;
