import { useCallback, useEffect, useRef, useState } from 'react';
import DataManager, { IDataManager } from '../utils/DataManager';
import { TObject } from '../../../common/types';
import debounce from 'lodash.debounce';
import { TUnControlColumn } from '../utils/types';

export interface IUseTableDataProps<RowData extends TObject> {
  columns: TUnControlColumn<RowData>[];
  data: RowData[];
  rowsPerPage: number;
  showAll: boolean;
  showPagination: boolean;
}

const useTableData = <RowData extends TObject>({
  columns,
  data: dataProps,
  rowsPerPage: rowsPerPageProp,
  showAll,
  showPagination: showPaginationProp,
}: IUseTableDataProps<RowData>) => {
  const dataManager = useRef<IDataManager<RowData>>(
    new DataManager(dataProps, columns, rowsPerPageProp, showAll, showPaginationProp),
  );
  const [searchValue, setSearchValue] = useState('');
  const [renderData, setRenderData] = useState(dataManager.current.getRenderData());

  const resetTableData = useCallback(dataProps => {
    setSearchValue('');
    dataManager.current.setData(dataProps);
    dataManager.current.setCurrentPage(0);
    setRenderData(dataManager.current.getRenderData());
  }, []);

  useEffect(() => {
    setRenderData(dataManager.current.getRenderData());
  }, []);

  const onChangePage = useCallback(
    (newPage: number) => {
      dataManager.current.setCurrentPage(newPage);
      setRenderData(dataManager.current.getRenderData());
    },
    [dataManager, setRenderData],
  );

  const onRowsPerPageChange = (rowPerPage: number) => {
    dataManager.current.setRowsPerPage(rowPerPage);
    dataManager.current.setCurrentPage(0);
    setRenderData(dataManager.current.getRenderData());
  };

  const debounceSearch = useCallback(
    debounce((text: string) => {
      dataManager.current.searchData(text);
      dataManager.current.setCurrentPage(0);
      setRenderData(dataManager.current.getRenderData());
    }, 200),
    [dataManager, setRenderData],
  );

  const onSearch = useCallback(
    (value: string) => {
      setSearchValue(value);
      debounceSearch(value);
    },
    [setSearchValue, debounceSearch],
  );

  const updateData = useCallback(
    (data: RowData[]) => {
      dataManager.current.setData(data);
    },
    [dataManager],
  );

  const onNewData = useCallback(
    (data: RowData[]) => {
      resetTableData(data);
    },
    [resetTableData],
  );

  return {
    searchValue,
    renderData,
    onChangePage,
    onRowsPerPageChange,
    onSearch,
    onNewData,
    updateData,
  };
};
export default useTableData;
