import * as React from 'react';

import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function Remove({ color = DefaultTheme.palette.grey[300] }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 9v10H8V9h8zm-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"
        fill={color}
      />
    </svg>
  );
}

export default Remove;
