import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function StoreMenu({ color = DefaultTheme.palette.grey[300] }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="storeMenu_svg__a" fill="#fff">
        <rect x={12} y={20} width={24} height={15} rx={1.5} />
      </mask>
      <rect
        x={12}
        y={20}
        width={24}
        height={15}
        rx={1.5}
        stroke={color}
        strokeWidth={4}
        mask="url(#storeMenu_svg__a)"
      />
      <path
        d="M37 20.66V21H11v-.34l6.394-2.74a1 1 0 00.5-.473L19.618 14h8.764l1.724 3.447a1 1 0 00.5.472L37 20.66z"
        stroke={color}
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path d="M21 26a1 1 0 011-1h4a1 1 0 011 1v8h-6v-8z" stroke={color} strokeWidth={2} />
    </svg>
  );
}

export default StoreMenu;
