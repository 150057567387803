import * as React from 'react';

const RewardOthers = () => (
  <svg width={20} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.016 1.027c0-.291.236-.527.527-.527h12.914c.291 0 .527.236.527.527v5.35a7.716 7.716 0 0 1-2.57 5.75L11.83 14.44a.527.527 0 0 1-.351.135H8.52a.527.527 0 0 1-.351-.134l-2.585-2.314a7.716 7.716 0 0 1-2.57-5.75v-5.35Z"
      fill="#fff"
      stroke="#262222"
      strokeLinejoin="round"
    />
    <path
      d="M5.527 19.6a3.095 3.095 0 0 1 3.077-2.769h2.79a3.095 3.095 0 0 1 3.078 2.769H5.527Z"
      fill="#fff"
      stroke="#262222"
    />
    <path fill="#fff" stroke="#262222" d="M8.629 14.081h2.742v2.769H8.629z" />
    <path
      d="M1.027 2.384h1.707l.47 7.389L1.78 8.94A2.581 2.581 0 0 1 .5 6.71v-3.8c0-.29.236-.527.527-.527ZM18.973 2.384h-1.707l-.47 7.389 1.425-.833A2.581 2.581 0 0 0 19.5 6.71v-3.8a.527.527 0 0 0-.527-.527Z"
      stroke="#262222"
      strokeLinejoin="round"
    />
  </svg>
);

export default RewardOthers;
