import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function Print({ color = DefaultTheme.palette.error.main }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={47} height={48} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.5 29H33v-8a2 2 0 00-2-2H16a2 2 0 00-2 2v8h3.523"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path stroke={color} strokeWidth={2} d="M18 14h11v5H18zM18 27h11v7H18z" />
      <circle cx={18} cy={23} r={1} fill={color} />
    </svg>
  );
}

export default Print;
