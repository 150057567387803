import React, { useMemo, useState } from 'react';
import { IconButton, TableCell, TableRow, Theme, TableCellClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TObject } from '../../../common/types';
import { TColumn } from '../type';
import { fonts } from '../../../themes/base/fonts';

export const useStyles = makeStyles((theme: Theme) => ({
  tableCellBody: {
    padding: theme.spacing(1.75, 2),
    ...fonts.NORMAL_875_125,
  },
  panelCell: {
    padding: 0,
    maxWidth: theme.spacing(3),
  },
  noneBottomBorder: {
    borderBottom: 'none',
  },
  noneTopBorder: {
    borderTop: 'none',
  },
  cellPanelIcon: {
    padding: theme.spacing(0.5, 0.5),
  },
}));

export interface IRowProps<RowData extends TObject> {
  classes?: Partial<TableCellClasses>;
  columns: TColumn<RowData>[];
  data: RowData;
  showPanel?: {
    render: (rowData: RowData) => string | React.ReactNode;
  };
}

export function Row<RowData extends TObject>({ classes: classesProps, columns, data, showPanel }: IRowProps<RowData>) {
  const classes = useStyles();
  const [panelOpen, setPanelOpen] = useState(false);
  const rotateIconStyle = useMemo(
    () => ({
      transform: panelOpen ? 'rotate(180deg)' : 'none',
    }),
    [panelOpen],
  );
  const cellClass = clsx(classes.tableCellBody, { [classes.noneBottomBorder]: panelOpen }, classesProps?.body);

  const cellRenders = useMemo(() => {
    return columns.map(({ field, align, render }) => {
      return (
        <TableCell
          classes={{
            root: cellClass,
          }}
          align={align}
          key={`${data[field]}_${field}`}>
          {render ? render(data) : data[field]}
        </TableCell>
      );
    });
  }, [columns, data, panelOpen]);

  return (
    <>
      <TableRow>
        {showPanel ? (
          <TableCell
            classes={{
              root: clsx(cellClass, classes.panelCell),
            }}
            key="key-detail-panel-column"
            style={{
              width: 42,
              textAlign: 'center',
            }}>
            <IconButton
              style={{
                transition: 'all ease 200ms',
                ...rotateIconStyle,
              }}
              onClick={event => {
                setPanelOpen(open => !open);
                event.stopPropagation();
              }}>
              <KeyboardArrowDownIcon />
            </IconButton>
          </TableCell>
        ) : null}
        {cellRenders}
      </TableRow>
      {showPanel && panelOpen ? (
        <TableRow>
          <TableCell
            classes={{
              root: clsx(classes.cellPanelIcon, classesProps?.body),
            }}
            colSpan={columns.length + 1}>
            {showPanel.render(data)}
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
}
