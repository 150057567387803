import tinycolor from 'tinycolor2';
import { fonts } from './fonts';

const primary = '#E32A33';
const secondary = '#fff';
const secondaryDark = 'rgba(0, 0, 0, 0.6)';
const warning = '#E5A117';
const success = '#339137';
const info = '#9013FE';
const error = '#E32A33';

const textPrimary = 'rgba(0, 0, 0, 0.87)';
const textSecondary = 'rgba(0, 0, 0, 0.6)';

const lightenRate = 7.5;
const darkenRate = 15;

const grey50 = '#F7F4F4';
const grey100 = '#E6E1E1';
const grey200 = '#E5E1E1';
const grey300 = '#B2ABAB';
const grey400 = '#736C6C';
const grey500 = '#323232';

const borderColorGrey = 'rgba(0, 0, 0, 0.12)';

export const DefaultTheme = {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: secondaryDark,
      contrastText: primary,
    },
    warning: {
      main: warning,
      light: tinycolor(warning).lighten(lightenRate).toHexString(),
      dark: tinycolor(warning).darken(darkenRate).toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    error: {
      main: error,
      light: tinycolor(error).lighten(lightenRate).toHexString(),
      dark: tinycolor(error).darken(darkenRate).toHexString(),
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
    },
    background: {
      default: '#F7F4F4',
    },
    grey: {
      50: grey50,
      100: grey100,
      200: grey200,
      300: grey300,
      400: grey400,
      500: grey500,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          border: '1px solid',
          borderColor: borderColorGrey,
          boxShadow: 'none',
        },
      },
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderWidth: '1px',
          borderColor: info,
        },
        color: textPrimary,
        ...fonts.NORMAL_75_2,
      },
      notchedOutline: {
        borderColor: grey300,
      },
      adornedEnd: {
        paddingRight: '12px',
      },
    },
    MuiSelect: {
      icon: {
        top: 'calc(50% - 4px)',
        right: '8px',
      },
      iconOutlined: {
        right: '16px',
      },
    },
    MuiChip: {
      root: {
        ...fonts.NORMAL_875_17,
      },
    },
    MuiInputLabel: {
      root: {
        ...fonts.NORMAL_75_2,
        color: textSecondary,
      },
    },
    MuiFormControlLabel: {
      root: {
        ...fonts.NORMAL_875_17,
      },
    },
    MuiInputAdornment: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiCheckbox: {
      root: {
        padding: 0,
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiRadio: {
      root: {
        padding: 0,
      },
    },
  },
};
