import React, { ElementType, PropsWithChildren } from 'react';
import { Button as MuiButton, ComponentsPropsList } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import { Loading } from '../Loading';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonRound: {
    borderRadius: '5em',
  },
  button: {},
  startIcon: {
    marginRight: theme.spacing(1),
    marginLeft: 0,
  },
}));

export type TButtonProps = ComponentsPropsList['MuiButton'] &
  PropsWithChildren<{ isLoading?: boolean; shape?: 'round'; component?: ElementType }>;

export function Button({ isLoading, children, shape, ...otherProps }: TButtonProps): React.ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <MuiButton
          classes={{
            startIcon: classes.startIcon,
          }}
          className={clsx(classes.button, {
            [classes.buttonRound]: shape === 'round',
          })}
          {...otherProps}>
          {children}
        </MuiButton>
        {isLoading && <Loading />}
      </div>
    </div>
  );
}
