import React from 'react';
import { Radio as MuiRadio, ComponentsPropsList, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { EIconTypes } from '../../constants';
import { Icon } from '../Icon';
import { FormControlLabel } from '@mui/material';
import { fonts } from '../../themes/base/fonts';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiTypography-root': {
      ...fonts.NORMAL_875_17,
      color: theme.palette.text.primary,
    },
  },
}));

export type TRadioProps = ComponentsPropsList['MuiRadio'] & {
  label?: string;
};

export function Radio({ label, ...props }: TRadioProps): React.ReactElement {
  const classes = useStyles();
  return (
    <FormControlLabel
      control={
        <MuiRadio
          icon={<Icon type={EIconTypes.ICON_RADIO} />}
          checkedIcon={<Icon type={EIconTypes.ICON_RADIO_CHECKED} />}
          {...props}
        />
      }
      label={label}
      classes={{ root: classes.root }}
    />
  );
}
