import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function IncreaseArrow({ color = DefaultTheme.palette.success.main }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 5v2h6.59L4 18.59 5.41 20 17 8.41V15h2V5H9z" fill={color} />
    </svg>
  );
}

export default IncreaseArrow;
