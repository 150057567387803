import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function UsersMenu({ color = DefaultTheme.palette.grey[300] }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.75 25.238c-2.925 0-8.75 3.522-8.75 6.514V32a2 2 0 002 2h13.5a2 2 0 002-2v-.248c0-2.992-5.825-6.514-8.75-6.514zm-5.825 6.193c0-1.835 3.587-3.624 5.825-3.624s5.825 2.34 5.825 3.624h-11.65zm5.825-8.44c2.413 0 4.375-2.017 4.375-4.496 0-2.479-1.962-4.495-4.375-4.495-2.413 0-4.375 2.017-4.375 4.495 0 2.48 1.962 4.496 4.375 4.496zm0-6.422c1.038 0 1.875.86 1.875 1.926 0 1.066-.837 1.927-1.875 1.927s-1.875-.86-1.875-1.927c0-1.066.837-1.926 1.875-1.926zm8.8 8.747C31 26.395 32 29.85 32 31.752V32a2 2 0 002 2h1a2 2 0 002-2v-.248c0-2.594-4.375-6.09-7.45-6.436zm-1.3-2.325c2.413 0 4.375-2.017 4.375-4.496 0-2.479-1.962-4.495-4.375-4.495-.675 0-1.3.167-1.875.45a7.12 7.12 0 011.25 4.045 7.12 7.12 0 01-1.25 4.046c.575.283 1.2.45 1.875.45z"
        fill={color}
      />
    </svg>
  );
}

export default UsersMenu;
