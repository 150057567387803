import React from 'react';
import { EIconTypes } from '../../constants';
import {
  Logout,
  CheckListMenu,
  Setting,
  StatusDanger,
  StatusSuccess,
  StatusWarning,
  DashboardMenu,
  StoreMenu,
  UsersMenu,
  ExportMenu,
  RoutinesManagementMenu,
  LocalizationMenu,
  EquipmentMenu,
  Filter,
  LeftArrow,
  RightArrow,
  Location,
  Print,
  Profile,
  ShowAll,
  IncreaseArrow,
  DecreaseArrow,
  Search,
  DownArrow,
  Calendar,
  Checkbox,
  CheckboxChecked,
  Close,
  Menu,
  Remove,
  Radio,
  RadioChecked,
  OpportunityMenu,
  Minus,
  Plus,
  ExportIcon,
  ImportExportMenu,
  StatusInProgress,
  StatusNotStarted,
  Humidity,
  Reward1,
  Reward2,
  Reward3,
  RewardOthers,
} from '../../icons';
import { DefaultTheme } from '../../themes/base';

export enum EIconColor {
  primary = 'primary',
  textPrimary = 'textPrimary',
  textSecondary = 'textSecondary',
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  grey = 'grey',
  grey200 = 'grey200',
}

export type TIconProps = { type: EIconTypes; color?: EIconColor; className?: string };
export function Icon({ type, color: colorProp, ...otherProps }: TIconProps): React.ReactElement {
  let color;
  switch (colorProp) {
    case EIconColor.primary:
      color = DefaultTheme.palette.primary.main;
      break;
    case EIconColor.textPrimary:
      color = DefaultTheme.palette.text.primary;
      break;
    case EIconColor.textSecondary:
      color = DefaultTheme.palette.text.secondary;
      break;
    case EIconColor.success:
      color = DefaultTheme.palette.success.main;
      break;
    case EIconColor.warning:
      color = DefaultTheme.palette.warning.main;
      break;
    case EIconColor.danger:
      color = DefaultTheme.palette.error.main;
      break;
    case EIconColor.grey:
      color = DefaultTheme.palette.grey[300];
      break;
    case EIconColor.grey200:
      color = DefaultTheme.palette.grey[200];
      break;
    default:
      break;
  }
  const iconProps = {
    color,
    ...otherProps,
  };
  switch (type) {
    case EIconTypes.ICON_STATUS_SUCCESS:
      return <StatusSuccess {...iconProps} />;
    case EIconTypes.ICON_STATUS_WARNING:
      return <StatusWarning {...iconProps} />;
    case EIconTypes.ICON_STATUS_DANGER:
      return <StatusDanger {...iconProps} />;
    case EIconTypes.ICON_STATUS_IN_PROGRESS:
      return <StatusInProgress {...iconProps} />;
    case EIconTypes.ICON_STATUS_NOT_STARTED:
      return <StatusNotStarted {...iconProps} />;
    case EIconTypes.ICON_SETTING:
      return <Setting {...iconProps} />;
    case EIconTypes.ICON_LOGOUT:
      return <Logout {...iconProps} />;
    case EIconTypes.ICON_DASHBOARD_MENU:
      return <DashboardMenu {...iconProps} />;
    case EIconTypes.ICON_CHECKLIST_MENU:
      return <CheckListMenu {...iconProps} />;
    case EIconTypes.ICON_STORE_MENU:
      return <StoreMenu {...iconProps} />;
    case EIconTypes.ICON_USERS_MENU:
      return <UsersMenu {...iconProps} />;
    case EIconTypes.ICON_EXPORT_MENU:
      return <ExportMenu {...iconProps} />;
    case EIconTypes.ICON_FILTER:
      return <Filter {...iconProps} />;
    case EIconTypes.ICON_RIGHT_ARROW:
      return <RightArrow {...iconProps} />;
    case EIconTypes.ICON_LEFT_ARROW:
      return <LeftArrow {...iconProps} />;
    case EIconTypes.ICON_LOCATION:
      return <Location {...iconProps} />;
    case EIconTypes.ICON_PRINT:
      return <Print {...iconProps} />;
    case EIconTypes.ICON_PROFILE:
      return <Profile {...iconProps} />;
    case EIconTypes.ICON_SHOW_ALL:
      return <ShowAll {...iconProps} />;
    case EIconTypes.ICON_INCREASE_ARROW:
      return <IncreaseArrow {...iconProps} />;
    case EIconTypes.ICON_DECREASE_ARROW:
      return <DecreaseArrow {...iconProps} />;
    case EIconTypes.ICON_SEARCH:
      return <Search {...iconProps} />;
    case EIconTypes.ICON_DOWN_ARROW:
      return <DownArrow {...iconProps} />;
    case EIconTypes.ICON_CALENDAR:
      return <Calendar {...iconProps} />;
    case EIconTypes.ICON_CHECKBOX:
      return <Checkbox {...iconProps} />;
    case EIconTypes.ICON_CHECKBOX_CHECKED:
      return <CheckboxChecked {...iconProps} />;
    case EIconTypes.ICON_MENU:
      return <Menu {...iconProps} />;
    case EIconTypes.ICON_CLOSE:
      return <Close {...iconProps} />;
    case EIconTypes.ICON_ROUTINES_MANAGER_MENU:
      return <RoutinesManagementMenu {...iconProps} />;
    case EIconTypes.ICON_EQUIPMENT_MENU:
      return <EquipmentMenu {...iconProps} />;
    case EIconTypes.ICON_LOCALIZATION_MENU:
      return <LocalizationMenu {...iconProps} />;
    case EIconTypes.ICON_RADIO:
      return <Radio {...iconProps} />;
    case EIconTypes.ICON_RADIO_CHECKED:
      return <RadioChecked {...iconProps} />;
    case EIconTypes.ICON_REMOVE:
      return <Remove {...iconProps} />;
    case EIconTypes.ICON_OPPORTUNITY_MENU:
      return <OpportunityMenu {...iconProps} />;
    case EIconTypes.ICON_MINUS:
      return <Minus {...iconProps} />;
    case EIconTypes.ICON_PLUS:
      return <Plus {...iconProps} />;
    case EIconTypes.ICON_EXPORT:
      return <ExportIcon {...iconProps} />;
    case EIconTypes.ICON_IMPORT_EXPORT_MENU:
      return <ImportExportMenu {...iconProps} />;
    case EIconTypes.ICON_HUMIDITY:
      return <Humidity {...iconProps} />;
    case EIconTypes.ICON_REWARD_1:
      return <Reward1 />;
    case EIconTypes.ICON_REWARD_2:
      return <Reward2 />;
    case EIconTypes.ICON_REWARD_3:
      return <Reward3 />;
    case EIconTypes.ICON_REWARD_OTHERS:
      return <RewardOthers />;
    default:
      return null;
  }
}
