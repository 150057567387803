import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function Close({ color = DefaultTheme.palette.grey[300] }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        d="M16.413 15.954l14.541 13.633M16.046 29.587l14.541-13.633"
      />
    </svg>
  );
}
export default Close;
