import React from 'react';
import { Checkbox as MuiCheckBox, ComponentsPropsList } from '@mui/material';
import { EIconTypes } from '../../constants';
import { Icon } from '../Icon';
import { FormControlLabel, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { fonts } from '../../themes/base/fonts';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiTypography-root': {
      ...fonts.NORMAL_875_17,
      color: theme.palette.text.primary,
    },
  },
}));

export type TCheckboxProps = ComponentsPropsList['MuiCheckbox'] & {
  label?: string;
};

export function Checkbox({ label, ...props }: TCheckboxProps): React.ReactElement {
  const classes = useStyles();
  return label ? (
    <FormControlLabel
      control={
        <MuiCheckBox
          icon={<Icon type={EIconTypes.ICON_CHECKBOX} />}
          checkedIcon={<Icon type={EIconTypes.ICON_CHECKBOX_CHECKED} />}
          {...props}
        />
      }
      label={label}
      classes={{ root: classes.root }}
    />
  ) : (
    <MuiCheckBox
      icon={<Icon type={EIconTypes.ICON_CHECKBOX} />}
      checkedIcon={<Icon type={EIconTypes.ICON_CHECKBOX_CHECKED} />}
      {...props}
    />
  );
}
