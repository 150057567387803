import React, { useMemo } from 'react';
import { Pagination } from '../Pagination';
import {
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  TableCellClasses,
} from '@mui/material';
import { fonts } from '../../themes/base/fonts';
import { TObject } from '../../common/types';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { TPaginationProps } from '../Pagination/Pagination';
import { Loading } from '../Loading';
import { Row } from './elements/Row';
import { TColumn } from './type';
import { TableClasses } from '@mui/material/Table/tableClasses';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    boxShadow: 'none',
    position: 'relative',
    border: 'none',
    borderRadius: 'unset',
  },
  tableContainer: {
    border: '1px solid #e0e0e0',
  },
  tablePaginationContainer: {
    border: 'none',
  },
  header: {},
  tableCellHead: {
    padding: theme.spacing(1.75, 2),
    ...fonts.BOLD_875_15,
  },
  tableCellBody: {
    padding: theme.spacing(1.75, 2),
  },
  tableCellFooter: {
    padding: 0,
    border: 'none',
    backgroundColor: theme.palette.background.default,
  },
  emptyMessage: {
    textAlign: 'center',
    padding: theme.spacing(5),
    ...fonts.NORMAL_75_2,
  },
  overlay: {
    backgroundColor: theme.palette.secondary.light,
  },
}));

export interface ITableProps<RowData extends TObject> {
  tableClasses?: Partial<TableClasses>;
  cellClasses?: Partial<TableCellClasses>;
  columns: TColumn<RowData>[];
  data: RowData[];
  onPageChange: TPaginationProps['onPageChange'];
  onRowsPerPageChange: TPaginationProps['onRowsPerPageChange'];
  count?: number;
  currentPage: number;
  rowsPerPage: number;
  emptyDataMessage?: string;
  showHeader?: boolean;
  showPagination?: boolean;
  isLoading?: boolean;
  paginationProps?: Omit<TPaginationProps, 'onPageChange' | 'onRowsPerPageChange' | 'page' | 'rowsPerPage' | 'count'>;
  showPanel?: {
    render: (rowData: RowData) => string | React.ReactNode;
    separateColumn?: boolean;
  };
}

export function Table<RowData extends TObject>({
  tableClasses,
  cellClasses,
  columns,
  data,
  count,
  onPageChange,
  onRowsPerPageChange,
  currentPage,
  rowsPerPage,
  emptyDataMessage = 'No data',
  showHeader = true,
  showPagination = true,
  isLoading,
  paginationProps,
  showPanel,
}: ITableProps<RowData>) {
  const classes = useStyles();
  const autoWidthColumns = useMemo(() => columns.filter(column => !column.width).length, [columns]);
  const autoWidth = `${Math.floor(100 / autoWidthColumns)}%`;
  const totalColumn = showPanel ? columns.length + 1 : columns.length;
  return (
    <TableContainer
      classes={{
        root: classes.container,
      }}
      component={Paper}>
      <MuiTable
        classes={{
          root: clsx(classes.tableContainer, tableClasses?.root),
        }}>
        {showHeader ? (
          <TableHead classes={{ root: classes.header }}>
            <TableRow>
              {showPanel?.separateColumn ? (
                <TableCell
                  classes={{
                    head: clsx(cellClasses?.head),
                  }}
                  sx={{ p: 0 }}
                />
              ) : null}
              {columns.map(({ title, field, align, width, minWidth }, index) => {
                const minWidthCss = minWidth ? { minWidth: `${minWidth}px` } : {};
                const colSpan = showPanel && !showPanel.separateColumn && index === 0 ? 2 : 1;
                return (
                  <TableCell
                    classes={{
                      head: clsx(classes.tableCellHead, cellClasses?.head),
                    }}
                    sx={{ width: width ? width : autoWidth, ...minWidthCss }}
                    align={align}
                    key={field}
                    colSpan={colSpan}>
                    {title}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {data.length ? (
            data.map((item, index) => (
              <Row key={index} columns={columns} classes={cellClasses} data={item} showPanel={showPanel} />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={totalColumn}>
                <Typography
                  variant="subtitle1"
                  classes={{
                    subtitle1: classes.emptyMessage,
                  }}>
                  {emptyDataMessage}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MuiTable>
      {showPagination ? (
        <MuiTable
          classes={{
            root: classes.tablePaginationContainer,
          }}>
          <TableBody>
            <TableRow>
              <TableCell
                classes={{
                  root: classes.tableCellFooter,
                }}
                variant={'footer'}>
                <Pagination
                  page={currentPage}
                  count={count ? count : data.length}
                  rowsPerPage={rowsPerPage}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChange}
                  disabled={isLoading}
                  {...paginationProps}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </MuiTable>
      ) : null}
      {isLoading ? (
        <Loading
          classes={{
            overlay: classes.overlay,
          }}
        />
      ) : null}
    </TableContainer>
  );
}
