import * as React from 'react';

const Reward2 = () => (
  <svg width={20} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.016 1.025c0-.29.235-.525.524-.525h12.92c.29 0 .524.235.524.525v5.36c0 2.19-.932 4.276-2.563 5.736l-2.592 2.32a.525.525 0 0 1-.35.134H8.521a.525.525 0 0 1-.35-.134l-2.592-2.32a7.697 7.697 0 0 1-2.563-5.735V1.025Z"
      fill="#CCC9C2"
      stroke="#262222"
      strokeLinejoin="round"
    />
    <path
      d="M11.887 8.49v.77H8.483v-.657l1.61-1.726c.161-.18.289-.338.382-.475.094-.14.161-.263.202-.373a.906.906 0 0 0-.01-.704.56.56 0 0 0-.222-.253.663.663 0 0 0-.356-.089c-.15 0-.28.037-.39.11a.696.696 0 0 0-.246.304c-.054.13-.082.277-.082.44h-.987c0-.295.07-.566.212-.813.14-.248.34-.445.598-.59.257-.149.562-.223.916-.223.348 0 .642.057.882.17.241.112.423.274.546.486.126.21.188.46.188.752 0 .164-.026.325-.078.482a2.18 2.18 0 0 1-.226.465 3.734 3.734 0 0 1-.349.465c-.136.157-.288.32-.454.489l-.865.97h2.133Z"
      fill="#262222"
    />
    <path
      d="M5.526 19.6a3.087 3.087 0 0 1 3.07-2.769h2.806a3.087 3.087 0 0 1 3.07 2.769H5.527Z"
      fill="#CCC9C2"
      stroke="#262222"
    />
    <path fill="#CCC9C2" stroke="#262222" d="M8.629 14.081h2.742v2.769H8.629z" />
    <path
      d="M1.025 2.384h1.71l.47 7.388-1.43-.834A2.574 2.574 0 0 1 .5 6.715V2.91c0-.29.235-.525.525-.525ZM18.975 2.384h-1.71l-.47 7.388 1.43-.834A2.574 2.574 0 0 0 19.5 6.715V2.91a.525.525 0 0 0-.525-.525Z"
      stroke="#262222"
      strokeLinejoin="round"
    />
  </svg>
);

export default Reward2;
