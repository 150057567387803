import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

const StatusInProgress = ({ color = DefaultTheme.palette.grey[300] }: TSvgTypeProps) => (
  <svg width={20} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x={1} y={5} width={18} height={18} rx={9} stroke={color} strokeWidth={2} />
    <path
      d="m6.947 17.31 5.698-5.698M8.525 11.182h4.243M13.182 15.475v-4.243"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path stroke={color} d="M9.5 2.5h1v2h-1z" />
    <rect x={11.5} y={0.5} width={1} height={3} rx={0.5} transform="rotate(90 11.5 .5)" stroke={color} />
  </svg>
);

export default StatusInProgress;
