import React from 'react';
import { TimePicker as MuiTimePicker, TimePickerProps } from '@mui/lab';
import { InputLabel, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LocalizationProviderProps } from '@mui/lab/LocalizationProvider/LocalizationProvider';
import { Input } from '../Input';
import { LocalizationProvider } from '../DatePicker';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    padding: theme.spacing(1, 2),
  },
}));
type TError = {
  error: boolean;
  helperText: string;
};
export type TTimePickerProps<TTime = unknown> = Omit<TimePickerProps<TTime>, 'renderInput'> & {
  providerProps?: LocalizationProviderProps;
  formLabel?: string;
  excludeProvider?: boolean;
  error?: TError;
};

export function TimePicker<TTime = unknown>({
  providerProps,
  formLabel,
  excludeProvider = false,
  error,
  ...TimePickerProps
}: TTimePickerProps<TTime>): React.ReactElement {
  const classes = useStyles();
  const timePicker = (
    <>
      {formLabel ? <InputLabel>{formLabel}</InputLabel> : null}
      <MuiTimePicker
        renderInput={params => (
          <Input
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            {...params}
            {...(error || {})}
          />
        )}
        {...TimePickerProps}
      />
    </>
  );
  return excludeProvider ? timePicker : <LocalizationProvider {...providerProps}>{timePicker}</LocalizationProvider>;
}
