import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function OpportunityMenu({ color = DefaultTheme.palette.grey[300] }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 25.167h2.333v-2.334H13.5v2.334zm0 4.666h2.333V27.5H13.5v2.333zm0-9.333h2.333v-2.333H13.5V20.5zm4.667 4.667H34.5v-2.334H18.167v2.334zm0 4.666H34.5V27.5H18.167v2.333zm0-11.666V20.5H34.5v-2.333H18.167zm-4.667 7h2.333v-2.334H13.5v2.334zm0 4.666h2.333V27.5H13.5v2.333zm0-9.333h2.333v-2.333H13.5V20.5zm4.667 4.667H34.5v-2.334H18.167v2.334zm0 4.666H34.5V27.5H18.167v2.333zm0-11.666V20.5H34.5v-2.333H18.167z"
        fill={color}
      />
    </svg>
  );
}

export default OpportunityMenu;
