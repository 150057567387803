import React from 'react';
import { DatePicker as MuiDatePicker, DatePickerProps } from '@mui/lab';
import { Stack, InputLabel, Theme } from '@mui/material';
import { Icon } from '../Icon';
import { EIconTypes } from '../../constants';
import { makeStyles } from '@mui/styles';
import { LocalizationProviderProps } from '@mui/lab/LocalizationProvider/LocalizationProvider';
import { Input } from '../Input';
import clsx from 'clsx';
import { LocalizationProvider } from '../DatePicker';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    padding: theme.spacing(1, 2),
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  delimiter: {
    padding: theme.spacing(1, 2),
    color: theme.palette.text.secondary,
  },
  dateContainer: {
    width: `calc(50% - ${theme.spacing(1)})`,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  dateContainerTo: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

export type TDateRangePickerProps<TDate = unknown> = Omit<
  DatePickerProps<TDate>,
  'renderInput' | 'onChange' | 'value'
> & {
  providerProps?: LocalizationProviderProps;
  formLabelFrom?: string;
  formLabelTo?: string;
  value: {
    from: TDate | null;
    to: TDate | null;
  };
  placeholder?: {
    from?: string;
    to?: string;
  };
  onChange: (value: { from: TDate | null; to: TDate | null }) => void;
};

enum EInputType {
  FROM,
  TO,
}

export function DateRangePicker<TDate = unknown>({
  providerProps,
  formLabelFrom,
  formLabelTo,
  value,
  onChange: onChangeProps,
  placeholder,
  ...datePickerProps
}: TDateRangePickerProps<TDate>): React.ReactElement {
  const classes = useStyles();

  const onChange = (type: EInputType, date, value) => {
    if (type === EInputType.FROM) {
      onChangeProps({ from: date, to: value ? value.to : null });
    } else {
      onChangeProps({ from: value ? value.from : null, to: date });
    }
  };

  return (
    <LocalizationProvider {...providerProps}>
      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
        <div className={classes.dateContainer}>
          {formLabelFrom ? <InputLabel>{formLabelFrom}</InputLabel> : null}
          <MuiDatePicker
            value={value.from}
            onChange={date => onChange(EInputType.FROM, date, value)}
            className={classes.input}
            renderInput={({ inputProps, ...params }) => (
              <Input
                inputProps={{
                  ...inputProps,
                  classes: {
                    input: classes.input,
                  },
                  placeholder: placeholder?.from || inputProps.placeholder,
                }}
                {...params}
              />
            )}
            components={{
              OpenPickerIcon: props => <Icon type={EIconTypes.ICON_CALENDAR} {...props} />,
            }}
            {...datePickerProps}
          />
        </div>
        <div className={clsx(classes.dateContainer, classes.dateContainerTo)}>
          {formLabelTo ? <InputLabel>{formLabelTo}</InputLabel> : null}
          <MuiDatePicker
            value={value.to}
            onChange={date => onChange(EInputType.TO, date, value)}
            className={classes.input}
            renderInput={({ inputProps, ...params }) => (
              <Input
                inputProps={{
                  ...inputProps,
                  classes: {
                    input: classes.input,
                  },
                  placeholder: placeholder?.to || inputProps.placeholder,
                }}
                {...params}
              />
            )}
            components={{
              OpenPickerIcon: props => <Icon type={EIconTypes.ICON_CALENDAR} {...props} />,
            }}
            {...datePickerProps}
          />
        </div>
      </Stack>
    </LocalizationProvider>
  );
}
