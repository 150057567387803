import { TDataWithSearch, TUnControlColumn } from './types';
import { TObject } from '../../../common/types';

export interface IDataManager<RowData extends TObject> {
  setCurrentPage: (currentPage: number) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  setData: (data: RowData[]) => void;
  getData: () => RowData[];
  getTotalPage: () => number;
  getRenderData: () => {
    columns: TUnControlColumn<TObject>[];
    data: RowData[];
    currentPage: number;
    count: number;
    showPagination: boolean;
    rowsPerPage: number;
  };
  getRenderRows: (page) => RowData[];
  searchData: (text: string) => void;
}

export default class DataManager<RowData extends TObject> implements IDataManager<RowData> {
  private data: TDataWithSearch<RowData>[];
  private searchedData: TDataWithSearch<RowData>[];
  private columns: TUnControlColumn<TObject>[];
  private currentPage: number;
  private rowsPerPage: number;
  private readonly showAll: boolean;
  private readonly showPagination: boolean;
  constructor(data, columns, rowsPerPage = 10, showAll = false, showPaginationProp = true) {
    this.data = data;
    this.searchedData = data;
    this.columns = columns;
    this.rowsPerPage = rowsPerPage;
    this.currentPage = 0;
    this.showAll = Boolean(showAll);
    this.showPagination = showPaginationProp;
    this.renderSearchField();
  }

  setCurrentPage(page) {
    this.currentPage = page;
  }

  setRowsPerPage(rowsPerPage) {
    this.rowsPerPage = rowsPerPage;
  }

  setSearchData(data) {
    this.searchedData = data;
  }

  setData(data) {
    this.data = data;
    this.setSearchData(data);
    this.renderSearchField();
  }

  getCount() {
    return this.searchedData.length;
  }

  getTotalPage() {
    return Math.ceil(this.getCount() / this.rowsPerPage);
  }

  getCurrentPage() {
    return this.currentPage;
  }

  getRowPerPage() {
    return this.rowsPerPage;
  }

  getShowAll() {
    return this.showAll;
  }

  getShowPagination() {
    return this.showPagination;
  }

  getRenderRows() {
    if (this.showAll) {
      return this.searchedData;
    }
    const page = this.getCurrentPage();
    return this.getTotalPage() > 1
      ? this.searchedData.slice(page * this.rowsPerPage, page * this.rowsPerPage + this.rowsPerPage)
      : this.searchedData;
  }

  getFieldValue(rowData, columnDef) {
    if (rowData[columnDef]) {
      if (typeof rowData[columnDef] === 'string' || typeof rowData[columnDef] === 'number') {
        return String(rowData[columnDef]).toLowerCase();
      } else {
        return String(rowData[columnDef].value).toLowerCase();
      }
    }
    return '';
  }

  renderSearchField() {
    this.data.forEach(row => {
      const search_content = [];
      this.columns.forEach(column => {
        if (column.searchable) {
          search_content.push(this.getFieldValue(row, column.field));
        }
      });
      row.search_content = search_content.join(' ');
    });
  }

  getData() {
    return this.data;
  }
  searchData(text: string): void {
    const searchText = text.trim().toLowerCase();
    const searchData = this.data.filter(row => row.search_content.includes(searchText));
    this.setSearchData(searchData);
  }

  getRenderData() {
    return {
      data: this.getRenderRows(),
      columns: this.columns,
      currentPage: this.getCurrentPage(),
      rowsPerPage: this.getRowPerPage(),
      count: this.getCount(),
      showPagination: !this.getShowAll() && this.getShowPagination(),
    };
  }
}
