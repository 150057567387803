import React from 'react';
import { TextField as MuiTextField, ComponentsPropsList, Theme } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  inputContainer: {},
  inputRoot: {
    width: '100%',
  },
  input: {
    padding: theme.spacing(2),
    height: '1.5rem',
    '&.MuiInputBase-inputAdornedEnd': {
      paddingRight: 0,
    },
    '&.MuiInputBase-inputAdornedStart': {
      paddingLeft: 0,
    },
  },
  notchedOutline: {
    borderWidth: '1px',
  },
}));

export type TInputProps = ComponentsPropsList['MuiTextField'];

export function Input({
  className,
  variant = 'outlined',
  classes: classesProp = {},
  InputProps,
  ...props
}: TInputProps): React.ReactElement {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      <MuiTextField
        variant={variant}
        className={classes.inputRoot}
        {...props}
        color={'primary'}
        InputProps={{
          className: classes.inputContainer,
          classes: {
            input: clsx(classes.input, classesProp.root),
          },
          ...InputProps,
        }}
      />
    </div>
  );
}
