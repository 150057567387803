import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function ImportExportMenu({ color = DefaultTheme.palette.grey[300] }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.667 29.845v-8.178h-2.333v8.178h-3.5l4.667 4.655 4.666-4.655h-3.5ZM20.501 13.5l-4.667 4.655h3.5v8.178h2.333v-8.178h3.5L20.501 13.5Zm8.166 16.345v-8.178h-2.333v8.178h-3.5l4.667 4.655 4.666-4.655h-3.5ZM20.501 13.5l-4.667 4.655h3.5v8.178h2.333v-8.178h3.5L20.501 13.5Z"
        fill={color}
      />
    </svg>
  );
}

export default ImportExportMenu;
