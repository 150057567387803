import * as React from 'react';

const Reward1 = () => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.016 1.007c0-.28.227-.507.507-.507h12.954c.28 0 .507.227.507.507v5.341a7.56 7.56 0 0 1-2.54 5.653l-2.619 2.326a.507.507 0 0 1-.337.128H8.512a.507.507 0 0 1-.337-.128l-2.62-2.326a7.56 7.56 0 0 1-2.54-5.653v-5.34Z"
      fill="#FFD11A"
      stroke="#262222"
      strokeLinejoin="round"
    />
    <path d="M10.241 4.218v4.987h-.984V5.353l-1.183.376v-.776l2.061-.735h.106Z" fill="#262222" />
    <path
      d="M5.523 19.44a3.027 3.027 0 0 1 3.013-2.739h2.927a3.027 3.027 0 0 1 3.012 2.74H5.523Z"
      fill="#FFD11A"
      stroke="#262222"
    />
    <path d="M8.629 14.12h2.742v2.739H8.63v-2.74Z" fill="#FFD11A" stroke="#262222" />
    <path
      d="M1.007 2.37h1.92l.3 7.334-1.47-.851A2.522 2.522 0 0 1 .5 6.671V2.877c0-.28.227-.508.507-.508ZM18.992 2.37H16.99l-.225 7.34 1.478-.857A2.522 2.522 0 0 0 19.5 6.671V2.877a.507.507 0 0 0-.508-.508Z"
      stroke="#262222"
      strokeLinejoin="round"
    />
  </svg>
);

export default Reward1;
