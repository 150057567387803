import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function Radio({ color = DefaultTheme.palette.grey[300] }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={52} height={52} fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x={17} y={17} width={18} height={18} rx={9} stroke={color} strokeWidth={2} />
    </svg>
  );
}

export default Radio;
