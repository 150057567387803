import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function Filter({ color = DefaultTheme.palette.text.primary }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={18} height={12} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 12h4v-2H7v2zM0 0v2h18V0H0zm3 7h12V5H3v2z" fill={color} />
    </svg>
  );
}

export default Filter;
