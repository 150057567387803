import React from 'react';
import { ComponentsPropsList, TablePagination, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TablePaginationActions from './TablePaginationAction';
import { Loading } from '../Loading';
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  navigationContainer: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    padding: theme.spacing(2, 0),
  },
}));

const defaultPagination = [5, 10, 20, 50];

export type TPaginationProps = ComponentsPropsList['MuiTablePagination'] & {
  disabled?: boolean;
  isLoading?: boolean;
};

export function Pagination({ disabled = false, isLoading = false, ...props }: TPaginationProps): React.ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.navigationContainer}>
      <TablePagination
        rowsPerPageOptions={defaultPagination}
        component={'div'}
        colSpan={3}
        SelectProps={{
          inputProps: {
            'aria-label': 'rows per page',
          },
          variant: 'outlined',
          disabled: disabled,
        }}
        {...props}
        ActionsComponent={props => <TablePaginationActions {...props} disabled={disabled} />}
      />
      {isLoading ? <Loading /> : null}
    </div>
  );
}
