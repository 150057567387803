import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function Profile({ color = DefaultTheme.palette.error.main }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 31h14" stroke={color} strokeWidth={2} strokeLinecap="square" />
      <path d="M15.055 35a9.001 9.001 0 0117.89 0h-17.89z" stroke={color} strokeWidth={2} />
      <circle cx={24} cy={18} r={5} stroke={color} strokeWidth={2} />
    </svg>
  );
}

export default Profile;
