import React from 'react';
import { CircularProgress, CircularProgressProps, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 11,
    opacity: 0.7,
    display: 'flex',
    alignItems: 'center',
  },
  centerAlign: { justifyContent: ' center' },
  leftAlign: { justifyContent: ' flex-start' },
  rightAlign: { justifyContent: ' flex-end' },
}));
export interface ILoadingPropsClasses {
  overlay?: string;
  progress?: string;
}
export type TLoadingProps = {
  color?: CircularProgressProps['color'];
  size?: number;
  text?: string;
  classes?: ILoadingPropsClasses;
  align?: 'center' | 'left' | 'right';
};

export function Loading({
  color,
  classes: classesProps = {},
  size = 24,
  align = 'center',
  text,
}: TLoadingProps): React.ReactElement {
  const classes = useStyles();
  return (
    <div
      className={clsx(
        classes.overlay,
        {
          [classes.centerAlign]: align === 'center',
          [classes.leftAlign]: align === 'left',
          [classes.rightAlign]: align === 'right',
        },
        classesProps.overlay,
      )}>
      <Stack className={classesProps.progress} alignItems={'center'} sx={{ mx: 2 }}>
        <CircularProgress color={color} size={size} />
        {text ? (
          <Typography color="textPrimary" variant="body1" sx={{ mt: 2 }}>
            {text}
          </Typography>
        ) : null}
      </Stack>
    </div>
  );
}
