import React from 'react';
import { Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { EIconTypes, EStatusTypes } from '../../constants';
import { Icon } from '../Icon';
import { TIconProps } from '../Icon/Icon';
import { fonts } from '../../themes/base/fonts';

const {
  ICON_STATUS_DANGER,
  ICON_STATUS_SUCCESS,
  ICON_STATUS_WARNING,
  ICON_STATUS_IN_PROGRESS,
  ICON_STATUS_NOT_STARTED,
} = EIconTypes;
const { STATUS_DANGER, STATUS_SUCCESS, STATUS_WARNING, STATUS_IN_PROGRESS, STATUS_NOT_STARTED } = EStatusTypes;

const statusIconMap: Record<EStatusTypes, TIconProps['type']> = {
  [STATUS_SUCCESS]: ICON_STATUS_SUCCESS,
  [STATUS_WARNING]: ICON_STATUS_WARNING,
  [STATUS_DANGER]: ICON_STATUS_DANGER,
  [STATUS_IN_PROGRESS]: ICON_STATUS_IN_PROGRESS,
  [STATUS_NOT_STARTED]: ICON_STATUS_NOT_STARTED,
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginLeft: theme.spacing(1),
    ...fonts.NORMAL_875_125,
  },
  success: {
    color: theme.palette.success.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  danger: {
    color: theme.palette.error.main,
  },
}));

export type TStatusProps = {
  type: EStatusTypes;
  label?: string;
};

export function Status({ type, label }: TStatusProps): React.ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Icon type={statusIconMap[type]} />
      {label ? (
        <Typography className={classes.label} color="textPrimary" variant="subtitle1">
          {label}
        </Typography>
      ) : null}
    </div>
  );
}
