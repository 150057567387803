export enum EIconTypes {
  ICON_STATUS_SUCCESS = 'iconSuccess',
  ICON_STATUS_WARNING = 'iconWarning',
  ICON_STATUS_DANGER = 'iconDanger',
  ICON_STATUS_IN_PROGRESS = 'iconInProgress',
  ICON_STATUS_NOT_STARTED = 'iconNotStarted',
  ICON_SETTING = 'iconSetting',
  ICON_LOGOUT = 'iconLogout',
  ICON_CHECKLIST_MENU = 'iconCheckListMenu',
  ICON_DASHBOARD_MENU = 'iconDashboardMenu',
  ICON_STORE_MENU = 'iconStoreMenu',
  ICON_USERS_MENU = 'iconUsersMenu',
  ICON_EXPORT_MENU = 'iconExportMenu',
  ICON_ROUTINES_MANAGER_MENU = 'iconRoutinesManagementMenu',
  ICON_EQUIPMENT_MENU = 'iconEquipmentMenu',
  ICON_LOCALIZATION_MENU = 'iconLocalizationMenu',
  ICON_OPPORTUNITY_MENU = 'iconOpportunityMenu',
  ICON_FILTER = 'iconFilter',
  ICON_LEFT_ARROW = 'iconLeftArrow',
  ICON_RIGHT_ARROW = 'iconRightArrow',
  ICON_LOCATION = 'iconLocation',
  ICON_PRINT = 'iconPrint',
  ICON_PROFILE = 'iconProfile',
  ICON_SHOW_ALL = 'iconShowAll',
  ICON_INCREASE_ARROW = 'iconIncreaseArrow',
  ICON_DECREASE_ARROW = 'iconDecreaseArrow',
  ICON_SEARCH = 'iconSearch',
  ICON_DOWN_ARROW = 'iconDownArrow',
  ICON_CALENDAR = 'iconCalendar',
  ICON_CHECKBOX = 'iconCheckbox',
  ICON_CHECKBOX_CHECKED = 'iconCheckboxChecked',
  ICON_CLOSE = 'iconClose',
  ICON_MENU = 'iconMenu',
  ICON_RADIO = 'iconRadio',
  ICON_RADIO_CHECKED = 'iconRadioChecked',
  ICON_REMOVE = 'iconRemove',
  ICON_MINUS = 'iconMinus',
  ICON_PLUS = 'iconPlus',
  ICON_EXPORT = 'iconExport',
  ICON_IMPORT_EXPORT_MENU = 'iconImportExportMenu',
  ICON_HUMIDITY = 'iconHumidity',
  ICON_REWARD_1 = 'iconReward1',
  ICON_REWARD_2 = 'iconReward2',
  ICON_REWARD_3 = 'iconReward3',
  ICON_REWARD_OTHERS = 'iconOthers',
}
