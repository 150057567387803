import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function Menu({ color = DefaultTheme.palette.grey[300] }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path stroke={color} strokeWidth={2} strokeLinecap="round" d="M13 15h22M13 23h22M13 31h22" />
    </svg>
  );
}

export default Menu;
