import React from 'react';
import { Box, Tabs as MuiTabs, Tab as MuiTab, ComponentsPropsList, tabsClasses } from '@mui/material';

type TTab = {
  label: string;
  disabled?: boolean;
  value?: string;
};
export type TTabsProps = ComponentsPropsList['MuiTabs'] & {
  tabs: TTab[];
  tabSx?: ComponentsPropsList['MuiTabs']['sx'];
};

export function Tabs({
  tabs,
  value,
  onChange: handleChange,
  sx = {},
  tabSx = {},
  ...otherProps
}: TTabsProps): React.ReactElement {
  return (
    <Box sx={{ flexGrow: 1, mb: 2, maxWidth: '100%' }}>
      <MuiTabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        allowScrollButtonsMobile
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: '0.3' },
          },
          ...sx,
        }}
        {...otherProps}>
        {tabs.map(tab => (
          <MuiTab
            sx={{ fontWeight: 'bold', px: 3, ...tabSx }}
            key={tab.label}
            label={tab.label}
            disabled={Boolean(tab.disabled)}
            value={tab.value}
          />
        ))}
      </MuiTabs>
    </Box>
  );
}
