import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function EquipmentMenu({ color = DefaultTheme.palette.grey[300] }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.333 21.667H13.5V24h12.833v-2.333zm0-4.667H13.5v2.333h12.833V17zM31 26.333v-4.666h-2.333v4.666H24v2.334h4.667v4.666H31v-4.666h4.667v-2.334H31zm-17.5 2.334h8.167v-2.334H13.5v2.334z"
        fill={color}
      />
    </svg>
  );
}

export default EquipmentMenu;
