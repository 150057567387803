import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function Search({ color = DefaultTheme.palette.grey[300] }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={13} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.094 9.813a4.225 4.225 0 004.23-4.22 4.225 4.225 0 00-4.23-4.218 4.225 4.225 0 00-4.23 4.219 4.225 4.225 0 004.23 4.218z"
        stroke={color}
        strokeWidth={2}
      />
      <path d="M11.17 12.344L9.197 9.813" stroke={color} strokeWidth={2} strokeLinecap="square" />
    </svg>
  );
}

export default Search;
