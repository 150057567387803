import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function Logout({ color = DefaultTheme.palette.grey[300] }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 17.123h6c.55 0 1-.473 1-1.053 0-.579-.45-1.052-1-1.052h-6c-1.1 0-2 .947-2 2.105v14.742c0 1.158.9 2.106 2 2.106h6c.55 0 1-.474 1-1.053 0-.58-.45-1.053-1-1.053h-6V17.124z"
        fill={color}
      />
      <path
        d="M32.65 24.126l-2.79-2.938c-.32-.337-.86-.105-.86.368v1.885h-7c-.55 0-1 .474-1 1.053 0 .58.45 1.053 1 1.053h7v1.885c0 .474.54.705.85.368l2.79-2.937c.2-.2.2-.537.01-.737z"
        fill={color}
      />
    </svg>
  );
}

export default Logout;
