import * as React from 'react';
import { DefaultTheme } from '../themes/base';
import { TSvgTypeProps } from './type';

function ShowAll({ color = DefaultTheme.palette.error.main }: TSvgTypeProps): React.ReactElement {
  return (
    <svg width={17} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 5.059l6.537 3.87L8.5 12.802 1.963 8.93 8.5 5.059z"
        stroke={color}
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M15.641 12.5l-7.07 4.072L1.498 12.5"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.641 16.5l-7.07 4.072L1.498 16.5"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ShowAll;
